<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card>
            <b-tabs pills v-model="tab_idx">
              <b-tab>
                <template v-slot:title>{{ $t("Receipt") }}</template>
                <div v-if="currpage == 'list'">
                  <p class="p-3" v-if="receiptlist">
                    <el-table
                      stripe
                      class="table-responsive table"
                      header-row-class-name="thead-light"
                      :data="receiptlist"
                      @row-click="row_clicked_event_receipt"
                    >
                      <el-table-column label="ID" prop="ID">
                        <template v-slot="{ row }">
                          {{ row.ID }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('Location')"
                        prop="LocationID"
                      ></el-table-column>
                      <el-table-column
                        :label="$t('Name')"
                        prop="Name"
                      ></el-table-column>
                      <el-table-column
                        :label="$t('Copy')"
                        prop="Coyp"
                      ></el-table-column>
                    </el-table>
                  </p>
                  <p class="p-3" v-else>
                    {{ $t("No Records") }}
                  </p>
                  <p class="p-3">
                    <el-button type="primary" @click="add('recepit')">{{
                      $t("Add")
                    }}</el-button>
                  </p>
                </div>
                <div v-if="currpage == 'edit'">
                  <b-form>
                    <b-row>
                      <b-col>
                        <p>{{ cur_record.ID }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Store')">
                          <select
                            class="form-control"
                            v-model="cur_record.LocationID"
                          >
                            <option></option>
                            <option
                              v-for="(lc, idx) in locationlist"
                              :key="idx"
                              :value="idx"
                            >
                              {{ idx }} - {{ lc.LocationName }}
                            </option>
                          </select>
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Name')">
                          <b-input
                            placeholder="Name"
                            class="form-control"
                            v-model="cur_record.Name"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('HeaderLine') + '1'">
                          <b-input
                            placeholder="HeaderLine1"
                            class="form-control"
                            v-model="cur_record.HeaderLine1"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('HeaderLine') + '2'">
                          <b-input
                            placeholder="HeaderLine2"
                            class="form-control"
                            v-model="cur_record.HeaderLine2"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('HeaderLine') + '3'">
                          <b-input
                            placeholder="HeaderLine3"
                            class="form-control"
                            v-model="cur_record.HeaderLine3"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('HeaderLine') + '4'">
                          <b-input
                            placeholder="HeaderLine4"
                            class="form-control"
                            v-model="cur_record.HeaderLine4"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('HeaderLine') + '5'">
                          <b-input
                            placeholder="HeaderLine5"
                            class="form-control"
                            v-model="cur_record.HeaderLine5"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('HeaderLine') + '6'">
                          <b-input
                            placeholder="HeaderLine6"
                            class="form-control"
                            v-model="cur_record.HeaderLine6"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('FooterLine') + '1'">
                          <b-input
                            placeholder="FooterLine1"
                            class="form-control"
                            v-model="cur_record.FooterLine1"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('FooterLine') + '2'">
                          <b-input
                            placeholder="FooterLine2"
                            class="form-control"
                            v-model="cur_record.FooterLine2"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('FooterLine') + '3'">
                          <b-input
                            placeholder="FooterLine3"
                            class="form-control"
                            v-model="cur_record.FooterLine3"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('FooterLine') + '4'">
                          <b-input
                            placeholder="FooterLine4"
                            class="form-control"
                            v-model="cur_record.FooterLine4"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('FooterLine') + '5'">
                          <b-input
                            placeholder="FooterLine5"
                            class="form-control"
                            v-model="cur_record.FooterLine5"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('FooterLine') + '6'">
                          <b-input
                            placeholder="FooterLine6"
                            class="form-control"
                            v-model="cur_record.FooterLine6"
                          />
                        </base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input :label="$t('Image')">
                          <b-form-file
                            v-model="image1"
                            :state="Boolean(image1)"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                          ></b-form-file>
                        </base-input>
                      </b-col>
                    </b-row>
                    <img
                      v-if="cur_record.LOGOData"
                      :src="cur_record.LOGOData"
                    />

                    <b-row class="mt-3">
                      <b-col class="text-center">
                        <el-button type="primary" @click="form_cancel">{{
                          $t("Back")
                        }}</el-button>
                      </b-col>
                      <b-col class="text-center">
                        <el-button
                          type="primary"
                          @click="form_submit_receipt"
                          >{{ $t("Submit") }}</el-button
                        >
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </b-tab>

              <b-tab>
                <template v-slot:title>{{ $t("Others") }} </template>
                <div v-if="currpage == 'list'">
                  <p class="p-3" v-if="otherlist">
                    <el-table
                      stripe
                      class="table-responsive table"
                      header-row-class-name="thead-light"
                      :data="otherlist"
                      @row-click="row_clicked_event_other"
                    >
                      <el-table-column label="ID" prop="ID">
                        <template v-slot="{ row }">
                          {{ row.ID }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </p>
                  <p class="p-3" v-else>
                    {{ $t("No Records") }}
                  </p>
                  <p class="p-3">
                    <el-button type="primary" @click="add('other')">{{
                      $t("Add")
                    }}</el-button>
                  </p>
                </div>
                <div v-if="currpage == 'edit'">
                  <b-form>
                    <b-row class="mt-3">
                      <b-col class="text-center">
                        <el-button type="primary" @click="form_cancel">{{
                          $t("Back")
                        }}</el-button>
                      </b-col>
                      <b-col class="text-center">
                        <el-button type="primary" @click="form_submit_other">{{
                          $t("Submit")
                        }}</el-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
  data() {
    return {
      tab_idx: 0,
      currpage: "list",
      cur_record: null,
      cur_opt: null,
      new_receipt: {
        ID: "",
        LocationID: "",
        Name: "",
        Copy: "",
        HeaderLine1: "",
        HeaderLine2: "",
        HeaderLine3: "",
        HeaderLine4: "",
        HeaderLine5: "",
        HeaderLine6: "",
        FooterLine1: "",
        FooterLine2: "",
        FooterLine3: "",
        FooterLine4: "",
        FooterLine5: "",
        FooterLine6: "",
        LogoWidth: 0,
        LogoHeight: 0,
        LOGOData: "",
      },
      new_other: {
        ID: "",
      },
      locationlist: null,
      receiptlist: null,
      otherlist: null,

      image1: null,

      token: "",
      user_id: 0,
    };
  },
  methods: {
    add(pagename) {
      this.currpage = "edit";
      this.cur_record = this.new_receipt;
      if (this.cur_record.TaxList) {
        this.cur_record.TaxList = this.cur_record.TaxList.split(",");
      } else {
        this.cur_record.TaxList = [];
      }
      this.cur_opt = "add";
    },
    row_clicked_event_receipt(row, event, column) {
      // console.log("row_clicked_event_receipt", row, event, column);
      this.currpage = "edit";
      this.cur_record = row;
      if (this.cur_record.TaxList) {
        this.cur_record.TaxList = this.cur_record.TaxList.split(",");
      } else {
        this.cur_record.TaxList = [];
      }
      this.cur_opt = "update";
    },
    row_clicked_event_other(row, event, column) {
      // console.log("row_clicked_event_other", row, event, column);
      this.currpage = "edit";
      this.cur_tax = this.new_tax;
      this.cur_record = row;
      if (this.cur_record.TaxList) {
        this.cur_record.TaxList = this.cur_record.TaxList.split(",");
      } else {
        this.cur_record.TaxList = [];
      }
      this.cur_opt = "update";
    },
    form_cancel() {
      this.currpage = "list";
    },
    async form_submit_receipt(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_record) {
        if (this.cur_record[key])
          bodyFormData.append(key, this.cur_record[key]);
      }
      bodyFormData.append("image1", this.image1);

      axios({
        method: "post",
        url: "/Api/Web/Setting/edit_receipt",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              setStore("active_page", "tax_tax");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_submit_other(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_record) {
        if (this.cur_record[key])
          bodyFormData.append(key, this.cur_record[key]);
      }

      axios({
        method: "post",
        url: "/Api/Web/Setting/other",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_receipt_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Setting",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.locationlist = rt.data.locations;
              that.receiptlist = rt.data.receipts;
              that.tab_idx = rt.data.tab_idx;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }

      return this.get_receipt_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
